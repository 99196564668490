import {
  ArrowDropDown, ArrowDropUp,
  CalendarToday,
  FileDownload,
  FileOpen,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Button, CardHeader, Icon, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import {
  FilterDatePicker,
  FilterInputSearch,
  FilterSelect,
  getWidth,
} from '../../components/filter';
import { FilterFormControl, StyledContainer } from '../../components/globals';
import Header from '../../components/header';
import { LicenseeList } from '../../components/licensee';
import { PaginationCard } from '../../components/pagination';
import { config } from '../../config';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
  useUnlimitedPaginationApi,
} from '../../hooks';
import { apiRoutes, request, routes } from '../../lib';
import { Dict, Licensee, LicenseeFilters, PayRate } from '../../model';

const LicenseeOverview = () => {
  const { t } = useTranslation();
  useTitle(t('Lizenznehmer'));
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  let balanceNetLiabilities = 0;
  let balanceNetAssets = 0;

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Lizenznehmer') },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [status, setFilterStatus] = useQueryState(
    '',
    'status',
    queryStateOptions
  );
  const [from, setFrom] = useQueryState<Date | null>(null, 'from', {
    ...queryStateOptions,
    valueType: 'Date',
  });

  const [to, setTo] = useQueryState<Date | null>(null, 'to', {
    ...queryStateOptions,
    valueType: 'Date',
  });

  const [payRate, setPayRate] = useQueryState('', 'payRate', queryStateOptions);

  const { data: payRates, isLoading: payRatesLoading } =
    useUnlimitedPaginationApi<Dict, PayRate>(apiRoutes.payRates);

  const { context } = useFilteredPaginationApi<LicenseeFilters, Licensee>(
    apiRoutes.licensees,
    { search, hasLicense: true, status, payRate, from, to },
    'name',
    'asc',
    config.pageSize
  );

  const calculateAssetsAndLiabilities = (licensees: Licensee[]) => {
    licensees.forEach((licensee) => {
      const balance = licensee.balance ? licensee.balance.balanceNetFloat : 0;
      if (licensee.balance && (balance > 0)) {
        balanceNetAssets = balanceNetAssets + (balance);
      } else if (licensee.balance && (balance < 0)) {
        balanceNetLiabilities = balanceNetLiabilities + balance;
      }
    });
  }

  calculateAssetsAndLiabilities(context?.data?.results || []);

  const billingMutation = useMutation(
    async () =>
      await request<{ count: number }>(apiRoutes.licenseesBilled, 'PUT'),
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries([apiRoutes.licensees]);
        enqueueSnackbar(
          t(
            'Das Abrechnungsdatum wurde erfolgreich für {{count}} Nutzungsdaten gesetzt.',
            {
              count: res.data.count,
            }
          ),
          {
            variant: 'success',
          }
        );
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          { variant: 'error' }
        );
      },
    }
  );

  return (
    <StyledContainer data-test="licensees-content">
      <Header
        title={t('Lizenznehmer')}
        breadcrumbs={breadcrumbs}
        actions={
          <>
            <LoadingButton
              variant="outlined"
              color="secondary"
              onClick={() => {
                if (
                  !window.confirm(
                    t(
                      `Wollen Sie wirklich alle offenen Nutzungsdaten als abgerechnet setzen?`
                    )
                  )
                ) {
                  return;
                }
                billingMutation.mutateAsync();
              }}
              loading={billingMutation.isLoading}
              startIcon={<CalendarToday/>}
            >
              {t('Abr LN setzen')}
            </LoadingButton>{' '}
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => {
                if (
                  !window.confirm(
                    t(`Wollen Sie wirklich alle Daten exportieren?`)
                  )
                ) {
                  event.preventDefault();
                  return false;
                }
              }}
              href={`${process.env.REACT_APP_API_URL}/${routes.bmdExportAll}`}
              startIcon={<FileDownload/>}
            >
              {t('BMD Export')}
            </Button>{' '}
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.licenseesExport}
              startIcon={<FileOpen/>}
            >
              {t('Auswertung')}
            </Button>
          </>
        }
      />
      <PaginationCard
        header={
          <CardHeader
            subheader={
              <>
                <StyledContainer style={{ textAlign: 'right' }}>
                  <Icon>
                    <ArrowDropUp
                      style={{ color: '#2e9717', fontWeight: "bold" }}
                      fontSize="small"
                    />
                  </Icon>
                  <Tooltip title={t<string>('Verbindlichkeiten')}>
                    <Typography variant="caption" style={{ fontSize: 'small', marginRight: '', color: '#2e9717', fontWeight: "bold" }}>
                      {t('{{balanceNetAssets}} €', { balanceNetAssets: balanceNetAssets.toFixed(2) })}
                    </Typography>
                  </Tooltip>

                  <Icon>
                    <ArrowDropDown
                      style={{ color: '#ff0000', fontWeight: "bold" }}
                      fontSize="small"
                    />
                  </Icon>
                  <Tooltip title={t<string>('Ausstehende Forderungen')}>
                    <Typography variant="caption" style={{ fontSize: 'small', color: '#ff0000', fontWeight: "bold" }} color="text.secondary">
                      {t('{{balanceNetLiabilities}} €', { balanceNetLiabilities: balanceNetLiabilities.toFixed(2) })}
                    </Typography>
                  </Tooltip>

                  <Typography variant="caption" style={{ fontSize: 'small', marginRight: '', color: '#2e9717', fontWeight: "bold" }}>
                    {t('')}
                  </Typography>
                  <br/>
                  <Tooltip title={t<string>('Die Summen werden über alle sichtbaren Einträge in der Tabelle gebildet. Filter werden also berücksichtigt.')}>
                    <Typography variant="caption" style={{ fontSize: 'x-small' }} color="text.secondary">
                      {t(' (Summe der Saldi für die  aktuelle Ansicht)')}
                    </Typography>
                  </Tooltip>
                </StyledContainer>
              </>
            }>
          </CardHeader>
        }
        context={context}
        hasFilters={!!(search || status || payRate || from || to)}
        resetFilters={() => {
          setFilterSearch('');
          setFilterStatus('');
          setPayRate('');
          setFrom(null);
          setTo(null);
        }}
        filters={
          <>
            <FilterInputSearch value={inputSearch} onChange={setInputSearch}/>
            <FilterSelect
              label={t('Status')}
              value={status}
              onChange={setFilterStatus}
            >
              <MenuItem value="">
                <em>{t('Alle')}</em>
              </MenuItem>
              <MenuItem value="active">{t('Aktiv')}</MenuItem>
              <MenuItem value="inactive">{t('Inaktiv')}</MenuItem>
            </FilterSelect>
            <FilterFormControl style={{ width: getWidth('medium') }}>
              <Autocomplete
                id="payRate"
                loading={payRatesLoading}
                options={payRates}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => setPayRate(value?.name || '')}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    fullWidth
                    value={payRate}
                    size="small"
                    label={t('Lizenzpaket')}
                  />
                )}
              />
            </FilterFormControl>
            <Box ml={2} mr={3}>
              -
            </Box>
            <FilterDatePicker
              label={t('Von')}
              value={from}
              onChange={(e: Date | null) => {
                if (!e) {
                  setFrom(null);
                  return;
                }
                // fix time issue: from date should be included in the filter, search param would be set to the date before the selected date
                const date = new Date(e);
                date.setHours(1, 0, 0, 0);
                setFrom(date);
              }}
              disableFuture={false}
            />
            <FilterDatePicker
              label={t('Bis')}
              value={to}
              onChange={(e: Date | null) => {
                if (!e) {
                  setTo(null);
                  return;
                }
                const date = new Date(e);
                // fix time issue: to date should be included in the filter, search param would be set to the date before the selected date
                date.setHours(23, 59, 59);
                setTo(date);
              }}
              disableFuture={false}
            />
          </>
        }
      >
        <LicenseeList/>
      </PaginationCard>
    </StyledContainer>
  );
};

export default LicenseeOverview;
