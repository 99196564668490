import { TextField } from '@mui/material';
import React, {
  Dispatch,
  SetStateAction,
  VoidFunctionComponent,
  useEffect,
  useState,
} from 'react';
import { DateFormat } from '../../model';
import { DatePickerSmall, FilterFormControl } from '../globals';
import { FilterWidth, getWidth } from './index';

export const FilterDatePicker: VoidFunctionComponent<{
  label: string;
  value: Date | null;
  onChange:
    | Dispatch<SetStateAction<Date | null>>
    | ((value: Date | null) => void);
  disableFuture?: boolean;
  clearable?: boolean;
  width?: FilterWidth;
}> = ({
  label,
  value,
  onChange,
  disableFuture = true,
  clearable = true,
  width = 190,
}) => {
  const [inputDate, setInputDate] = useState<Date | string>('');

  useEffect(() => {
    if (
      inputDate instanceof Date &&
      !isNaN(inputDate.valueOf()) &&
      inputDate.getFullYear() >= 2000
    ) {
      onChange(inputDate);
    }
  }, [inputDate]);

  return (
    <FilterFormControl style={{ width: getWidth(width) }}>
      <DatePickerSmall
        label={label}
        value={value}
        // TODO: month-forward button seems to be buggy with maxDate enabled. Check on next release.
        //maxDate={filterToDate}
        inputFormat={DateFormat.Default}
        mask={DateFormat.Mask}
        onChange={(date: any) => {
          setInputDate(date);
        }}
        renderInput={(props) => (
          <TextField
            {...props}
            size="small"
            variant="outlined"
            style={{ width: '100%' }}
          />
        )}
        disableFuture={disableFuture}
        clearable={clearable}
      />
    </FilterFormControl>
  );
};
